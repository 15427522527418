import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Reactions = () => {
  const title = 'What people are saying';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <ul>
        <li>
          <Link to="/reactions/media">Media publications</Link>
        </li>
        <li>
          <Link to="/reactions/letters">Students' letters and replies</Link>
        </li>
      </ul>
    </Layout>
  );
};

export default Reactions;
